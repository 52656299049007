import React from 'react';

const Home = () => {
    return (
        <div className="text-center mt-5">
            <h1>주식회사 눈쟁이에스씨 고객 서비스 포털</h1>
            <p className="mt-4">
                이용약관 및 개인정보처리방침을 확인하실 수 있습니다.
            </p>
        </div>
    );
};

export default Home;