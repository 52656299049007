import React, {Component} from "react";
import {Link} from 'react-router-dom';
import {Navbar, Button} from 'reactstrap';

export class Navigation extends Component {
    render() {
        return (
            <div>
                <Navbar dark color="dark" expand="md" className="px-4">
                    <Link to="/" className="navbar-brand" style={{textDecoration: 'none'}}>
                        주식회사 눈쟁이에스씨 고객 서비스 포털
                    </Link>
                    <div className="ms-auto">
                        <Link to="/tos" style={{textDecoration: 'none', marginRight: '10px'}}>
                            <Button color="light" outline>
                                이용약관
                            </Button>
                        </Link>
                        <Link to="/privacy" style={{textDecoration: 'none'}}>
                            <Button color="light" outline>
                                개인정보처리방침
                            </Button>
                        </Link>
                    </div>
                </Navbar>
            </div>
        );
    }
}
