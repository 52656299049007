export const Tos20250116 = {
    date: "20250116",
    name: "2025년 01월 16일 개정, 2025년 01월 23일 시행",
    pages: [
        {
            name: "서비스의 목적에 관한 사항",
            element: <>
                본 약관은 눈쟁이에스씨(이하 '서비스')의 이용 조건 및 절차, 이용자와 서비스 제공자 간의 권리, 의무 및 책임 사항을 규정함을 목적으로 합니다.
            </>
        },
        {
            name: "용어의 정의에 관한 사항",
            element: <>
                본 약관에서 사용하는 용어의 정의는 다음과 같습니다.
                <ul>
                    <li>"이용자": 서비스에 접속하여 본 약관에 따라 서비스를 이용하는 회원 및 비회원을 말합니다.</li>
                    <li>"회원": 서비스에 개인정보를 제공하여 회원등록을 한 자로서, 서비스의 정보를 지속적으로 제공받으며, 서비스가 제공하는 서비스를 계속적으로 이용할 수 있는 자를
                        말합니다.
                    </li>
                    <li>"비회원": 회원에 가입하지 않고 서비스가 제공하는 서비스를 이용하는 자를 말합니다.</li>
                </ul>
            </>
        },
        {
            name: "서비스 이용 계약의 성립에 관한 사항",
            element: <>
                이용 계약은 이용자가 본 약관의 내용에 동의하고 회원가입 신청을 하며, 서비스가 이를 승낙함으로써 성립합니다.
                <br/>
                서비스는 만 14세 미만의 아동의 회원가입을 제한할 수 있습니다.
            </>
        },
        {
            name: "회원의 의무에 관한 사항",
            element: <>
                회원은 본 약관에서 규정하는 사항과 기타 서비스가 정한 제반 규정, 공지사항 등 서비스가 고지하는 사항을 준수하여야 합니다.
                <br/>
                회원은 서비스의 운영에 방해가 되는 행위를 하여서는 안 됩니다.
            </>
        },
        {
            name: "서비스 제공에 관한 사항",
            element: <>
                서비스는 회원에게 다음과 같은 서비스를 제공합니다.
                <ul>
                    <li>디스코드 봇 서비스 운영</li>
                    <li>디스코드 봇 전용 사이트 운영</li>
                    <li>눈쟁DB 사이트 운영</li>
                    <li>기타 서비스가 자체적으로 개발하거나 다른 회사와의 협력을 통해 제공하는 서비스</li>
                </ul>
                서비스는 필요한 경우 서비스의 내용을 변경할 수 있으며, 변경된 서비스의 내용 및 제공일자를 약관 변경 절차에 따라 공지합니다.
            </>
        },
        {
            name: "개인정보 처리 및 보호에 관한 사항",
            element: <>
                서비스는 이용자의 개인정보를 보호하고 존중합니다. 관련 법령 및 개인정보 처리방침에 따라 이용자의 개인정보를 안전하게 처리합니다.
                <br/>
                서비스의 개인정보 처리방침은 별도로 고지한 바에 따릅니다.
            </>
        },
        {
            name: "저작권 및 지적 재산권에 관한 사항",
            element: <>
                서비스가 제공하는 모든 콘텐츠(텍스트, 그래픽, 로고, 아이콘, 이미지, 오디오 클립, 디지털 다운로드, 데이터 컴파일레이션 등)의 저작권은 눈쟁이에스씨에 속합니다.
                <br/>
                이용자는 서비스를 이용함으로써 얻은 정보 중 눈쟁이에스씨 또는 제3자의 저작권 등 지적재산권에 속하는 정보를 눈쟁이에스씨의 사전 동의 없이 복제, 송신, 출판, 배포, 방송 기타 방법에
                의하여 영리 목적으로 이용하거나 제3자에게 이용하게 해서는 안 됩니다.
            </>
        },
        {
            name: "책임의 제한에 관한 사항",
            element: <>
                눈쟁이에스씨는 천재지변, 전쟁, 테러 행위, 폭동, 민란, 국가 비상사태, 서비스 공급자의 고의적 또는 과실적 행위 등 불가항력적 사건으로 인하여 서비스를 제공할 수 없을 경우 책임이
                면제됩니다.
                <br/>
                눈쟁이에스씨는 이용자의 고의 또는 과실로 인한 서비스 이용 장애에 대해서는 책임을 지지 않습니다.
                <br/>
                눈쟁이에스씨는 이용자 간 또는 이용자와 제3자 상호 간에 서비스를 매개로 발생한 분쟁에 대하여 책임을 지지 않습니다.
            </>
        },
        {
            name: "분쟁 해결 및 관할 법원에 관한 사항",
            element: <>
                서비스 이용과 관련하여 눈쟁이에스씨와 이용자 간에 발생한 분쟁은 대한민국 법률을 적용하여 해결합니다.
                <br/>
                서비스 이용 중 발생한 분쟁에 대해 소송이 제기될 경우, 눈쟁이에스씨의 본사 소재지를 관할하는 법원을 관할 법원으로 합니다.
            </>
        },
        {
            name: "약관의 변경에 관한 사항",
            element: <>
                눈쟁이에스씨는 약관이 변경될 경우, 변경 사항을 서비스 공지사항을 통해 공지합니다.
                <br/>
                변경된 약관은 공지된 시점부터 효력이 발생하며, 이용자는 변경된 약관에 동의하지 않을 경우 서비스 이용을 중단하고 회원 탈퇴를 할 수 있습니다.
            </>
        },
        {
            name: "환불 정책에 관한 사항",
            element: <>
                <strong>목적 및 적용 범위</strong>
                <br/>
                본 환불 정책은 눈쟁이에스씨(이하 "회사")가 운영하는 온라인 정보 서비스 ‘눈쟁DB’(이하 "서비스") 이용 시 발생할 수 있는 환불 관련 사항을 규정하기 위함입니다.
                <br/>
                본 정책은 ‘눈쟁DB’에서 유료 결제로 제공되는 디지털 콘텐츠(자료, 강의 등)에 한해 적용됩니다.
                <br/>
                <strong>환불 가능 및 조건</strong>
                <ul>
                    <li>회사 귀책 사유(오류, 접속 장애 등): 결제 후 24시간 이내 서비스 장애로 인해 정상적인 이용이 불가능했던 경우, 전액 환불이 가능합니다.</li>
                    <li>단순 변심: 결제 후 콘텐츠 열람이나 다운로드 기록이 없는 경우, 결제일로부터 24시간 이내 환불을 요청할 수 있습니다. 이미 일부 혹은 전부 열람/다운로드한 경우, 디지털
                        콘텐츠 특성상 환불이 제한될 수 있습니다.
                    </li>
                    <li>기타: 프로모션 상품, 이벤트성 상품 등은 별도 환불 규정이 있을 수 있으며, 관련 안내는 해당 상품 페이지에서 확인할 수 있습니다.</li>
                    <li>법률상 청약철회 제한 대상(즉시 사용·복제 가능 콘텐츠 등)은 환불이 불가능합니다.</li>
                </ul>
                <strong>환불 요청 방법</strong>
                <ul>
                    <li>이메일: refund@eyejokerdb.com</li>
                    <li>신청 시 결제내역(주문번호, 결제일 등)과 환불 사유를 알려주시면, 접수 후 7영업일 이내 처리됩니다.</li>
                    <li>신용카드 결제 건은 승인 취소로 환불되며, 카드사 정책에 따라 취소 반영이 지연될 수 있습니다.</li>
                </ul>
                <strong>책임 및 제한 사항</strong>
                <ul>
                    <li>고객 PC나 네트워크 문제 등 회사가 책임지지 않는 사유로 인한 서비스 불가 시, 환불이 제한될 수 있습니다.</li>
                    <li>이미 열람·다운로드된 디지털 콘텐츠는 재사용·복제가 가능하므로 환불이 거절될 수 있습니다.</li>
                </ul>
                <strong>정책 변경 안내</strong>
                <br/>
                회사는 본 환불 정책을 변경할 수 있으며, 변경 시 최소 7일 전 공지를 통해 안내합니다. 변경된 정책은 공지 시 안내된 시행일부터 적용됩니다.
            </>
        },
        {
            name: "기타 조항에 관한 사항",
            element: <>
                서비스는 필요한 경우 특정 서비스나 기능의 이용을 제한할 수 있으며, 이에 대한 사전 통지를 할 수 있습니다.
                <br/>
                서비스는 이용자가 본 약관의 규정을 위반하거나 서비스의 정상적인 운영을 방해한 경우, 서비스 이용을 제한하거나 중지시킬 수 있습니다.
                <br/>
                본 약관은 서비스를 이용하는 모든 이용자에게 적용됩니다.
            </>
        }
    ]
}
