export const Privacy20250116 = {
    date: "20250116",
    name: "2025년 01월 16일 개정, 2025년 01월 23일 시행",
    pages: [
        {
            name: "개인정보의 처리 목적",
            element: <>
                주식회사 눈쟁이에스씨(이하 '회사')는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
                <br/>
                <br/>
                1. 홈페이지 회원가입 및 관리
                <br/>
                회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 서비스 부정이용 방지, 각종 고지·통지, 고충처리 목적으로 개인정보를 처리합니다.
                <br/>
                <br/>
                2. 민원사무 처리
                <br/>
                민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락·통지, 처리결과 통보 목적으로 개인정보를 처리합니다.
                <br/>
                <br/>
                3. 재화 또는 서비스 제공
                <br/>
                물품배송, 서비스 제공, 계약서·청구서 발송, 콘텐츠 제공, 맞춤서비스 제공, 본인인증, 연령인증, 요금결제·정산, 채권추심을 목적으로 개인정보를 처리합니다.
                <br/>
                <br/>
                4. 마케팅 및 광고에의 활용
                <br/>
                신규 서비스(제품) 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공 및 참여기회 제공, 인구통계학적 특성에 따른 서비스 제공 및 광고 게재, 서비스의 유효성 확인, 접속빈도 파악 또는 회원의 서비스 이용에 대한 통계 등을 목적으로 개인정보를 처리합니다.
                <br/>
                <br/>
                5. 눈쟁DB 회원가입 및 로그인<br/>눈쟁DB 회원가입 및 로그인, 결제 기능 제공을 목적으로 개인정보를 처리합니다.
            </>
        },
        {
            name: "개인정보의 처리 및 보유 기간",
            element: <>
                ① 회사는 정보주체로부터 개인정보를 수집할 때 동의받은 개인정보 보유·이용기간 또는 법령에 따른 개인정보 보유·이용기간 내에서 개인정보를 처리·보유합니다.
                <br/>
                ② 구체적인 개인정보 처리 및 보유 기간은 다음과 같습니다.
                <br/>
                <br/>
                - 디스코드 봇 서비스 운영 : 서비스 제공 완료 시까지 보관
                <br/>
                - 디스코드 봇 전용 사이트 운영 : 서비스 제공 완료 시까지 보관
                <br/>
                - 눈쟁DB 사이트 운영 : 서비스 제공 완료 시까지 보관
                <br/>
                - 눈쟁DB 회원가입 및 로그인 : 회원 탈퇴 또는 목적 달성 시까지 보관
            </>
        },
        {
            name: "처리하는 개인정보의 항목",
            element: <>
                ① 회사는 다음의 개인정보 항목을 처리하고 있습니다.
                <br/>
                <br/>
                1. 디스코드 봇 서비스 운영
                <br/>
                필수 항목: 디스코드 계정 식별자, 디스코드 계정 프로필 사진, 디스코드 계정 이름, 디스코드 서버 식별자, 디스코드 서버 이름, 디스코드 채널 식별자, 디스코드 채널 이름, 디스코드 작성 메시지, 디스코드 메시지에 첨부된 파일, 디스코드 메시지 작성 일자, 디스코드 메시지 수정 일자, 디스코드 메시지 삭제 일자
                <br/>
                선택 항목: 디스코드 메시지 반응, 디스코드 봇이 보낸 버튼 클릭
                <br/>
                <br/>
                2. 디스코드 봇 전용 사이트 운영
                <br/>
                필수 항목: 서비스 이용 기록, 접속 로그, 쿠키, 접속 IP 정보, 라이엇게임즈 계정 식별자, 라이엇게임즈 계정 닉네임, 라이엇게임즈의 게임을 실행하여 활동한 게임의 랭크 정보, 디스코드 계정 식별자, 디스코드 계정 프로필 사진, 디스코드 계정 이름
                <br/>
                <br/>
                3. 눈쟁DB 회원가입 및 로그인
                <br/>
                필수 항목: 아이디, 비밀번호, CI(연계정보), DI(중복가입확인정보), 실명, 생년월일
                <br/>
                <br/>
                4. Google Analytics
                <br/>
                - 회사는 서비스 이용 및 통계 분석을 목적으로 Google LLC가 제공하는 Google Analytics를 이용하고 있습니다.
                <br/>
                - Google Analytics를 통해 개인을 식별할 수 있는 정보는 수집하지 않습니다.
                <br/>
                - Google 개인정보 처리방침 : https://policies.google.com/privacy?hl=ko
                <br/>
                - Google Analytics 서비스 약관 : https://marketingplatform.google.com/about/analytics/terms/kr/
                <br/>
                - Google Analytics 차단 브라우저 부가 기능 : https://tools.google.com/dlpage/gaoptout
            </>
        },
        {
            name: "개인정보의 파기절차 및 파기방법",
            element: <>
                회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체 없이 해당 개인정보를 파기합니다.
                <br/>
                개인정보 파기의 절차 및 방법은 다음과 같습니다.
                <br/>
                <br/>
                1. 파기절차
                <br/>
                회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.
                <br/>
                <br/>
                2. 파기방법
                <br/>
                전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다.
                <br/>
                종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.
            </>
        }
    ]
}
